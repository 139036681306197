import { createGlobalStyle } from 'styled-components'
import ChathuraRegularWoff from './fonts/Chathura/Chathura-Regular.woff'
import ChathuraRegularWoff2 from './fonts/Chathura/Chathura-Regular.woff2'
import ChathuraBoldWoff from './fonts/Chathura/Chathura-Bold.woff'
import ChathuraBoldWoff2 from './fonts/Chathura/Chathura-Bold.woff2'

// Chathura
const Chathura = {
  regular: {
    woff: ChathuraRegularWoff,
    woff2: ChathuraRegularWoff2,
  },
  bold: {
    woff: ChathuraBoldWoff,
    woff2: ChathuraBoldWoff2,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Chathura';
    src: local('Chathura'),
      url(${Chathura.regular.woff2}) format('woff2'),
      url(${Chathura.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Chathura';
    src: local('Chathura'),
      url(${Chathura.bold.woff2}) format('woff2'),
      url(${Chathura.bold.woff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
`

export default Fonts
