import React from 'react'
import styled from 'styled-components'
import { ReactComponent as OccamLogo } from 'img/occam-logo.svg'
import { ReactComponent as Logo } from 'img/logo.svg'
import bgAnim from 'img/bg-anim.svg'
import bg from 'img/bg.jpg'
import {
  DISCORD_LINK,
  MEDIUM_LINK,
  PARTNER_LINK,
  TWITTER_LINK,
} from 'configs/app'
import Navigation from './Navigation'
import useWindowSize from 'helpers/utils/useWindowSize'
import { breakpoints } from 'theme/theme'
import { ReactComponent as Twitter } from 'img/socials/twitter.svg'
import { ReactComponent as Discord } from 'img/socials/discord.svg'
import { ReactComponent as Medium } from 'img/socials/medium.svg'

function Index() {
  const { width } = useWindowSize()
  return (
    <Root>
      <BgAnim />
      <Header>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        {width > breakpoints.sm && <Navigation />}
      </Header>
      <TitleWrapper>
        <SubTitle>Synthetics </SubTitle>
        <Title>
          <span>Homo</span>genized
        </Title>
      </TitleWrapper>
      {width <= breakpoints.sm && <Navigation />}
      <Footer>
        <Socials>
          <SocialLink href={MEDIUM_LINK} target={'_blank'}>
            <Medium />
          </SocialLink>
          <SocialLink href={DISCORD_LINK} target={'_blank'}>
            <Discord />
          </SocialLink>
          <SocialLink href={TWITTER_LINK} target={'_blank'}>
            <Twitter />
          </SocialLink>
        </Socials>
        <PartnerLogoWrapper href={PARTNER_LINK} target="_blank">
          <OccamLogo />
        </PartnerLogoWrapper>
      </Footer>
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: url(${bg}) no-repeat center;
  background-size: cover;
`

const Header = styled.div`
  position: absolute;
  top: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 79px;
  ${({ theme }) => theme.adaptive.main.xl} {
    top: 48px;
    padding: 0 66px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    top: 42px;
    padding: 0 59px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    top: 36px;
    padding: 0 53px;
  }
  ${({ theme }) => theme.adaptive.main.sslg} {
    top: 29px;
    padding: 0 42px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    top: 40px;
    padding: 0 66px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    top: 20px;
    padding: 0 44px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    top: 25px;
    padding: 0 0 0 46px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    top: 25px;
    padding: 0;
  }
`

const BgAnim = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url(${bgAnim}) no-repeat center;
  background-size: cover;
`

const LogoWrapper = styled.div`
  width: 175px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.main.xl} {
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 162px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 150px;
  }
  ${({ theme }) => theme.adaptive.main.sslg} {
    width: 137px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: auto;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    width: 100%;
    text-align: center;
  }
  svg {
    display: block;
    width: 100%;
    height: auto;
    ${({ theme }) => theme.adaptive.main.md} {
      width: 175px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      width: 166px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      width: 129px;
    }
    ${({ theme }) => theme.adaptive.main.xsm} {
      margin: 0 auto;
    }
  }
`

const PartnerLogoWrapper = styled.a`
  display: block;
  width: 205px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 171px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 154px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 137px;
  }
  ${({ theme }) => theme.adaptive.main.sslg} {
    width: 109px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 171px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 120px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    position: absolute;
    bottom: 134px;
    left: 50%;
    transform: translateX(-50%);
    width: 145px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    width: 116px;
    bottom: 129px;
  }
  svg {
    width: 100%;
    height: auto;
    ${({ theme }) => theme.adaptive.main.md} {
      width: 171px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      width: 119px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      width: 145px;
    }
    ${({ theme }) => theme.adaptive.main.xsm} {
      width: 116px;
    }
  }
`

const TitleWrapper = styled.div`
  position: relative;
  text-align: center;
  z-index: 2;
  padding-bottom: 145px;
  ${({ theme }) => theme.adaptive.main.lg} {
    padding-bottom: 135px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding-bottom: 125px;
  }
  ${({ theme }) => theme.adaptive.main.sslg} {
    padding-bottom: 105px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding-bottom: 135px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding-bottom: 98px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding-bottom: 80px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    padding-bottom: 60px;
  }
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 113px;
  line-height: 60%;
  color: #b4b4b4;
  letter-spacing: 0.05em;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 94px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 85px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 75px;
  }
  ${({ theme }) => theme.adaptive.main.sslg} {
    font-size: 60px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 95px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 66px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 53px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    font-size: 42px;
  }
`

const Title = styled.div`
  color: #221f1f;
  font-weight: 700;
  font-size: 182px;
  line-height: 60%;
  letter-spacing: 0.05em;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 150px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 135px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 120px;
  }
  ${({ theme }) => theme.adaptive.main.sslg} {
    font-size: 96px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 150px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 105px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 85px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    font-size: 66px;
  }
  span {
    color: #e94e4e;
  }
`

const Footer = styled.div`
  position: absolute;
  bottom: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 79px;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 0 66px;
    bottom: 56px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 0 59px;
    bottom: 49px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding: 0 53px;
  }
  ${({ theme }) => theme.adaptive.main.sslg} {
    padding: 0 42px;
    bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 0 66px;
    bottom: 47px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 0 44px;
    bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    bottom: 0;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
  }
`

const Socials = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.main.sm} {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 44px;
    justify-content: center;
    background: #fff;
  }
`

const SocialLink = styled.a`
  display: block;
  max-width: 25px;
  max-height: 20px;
  margin-right: 48px;
  ${({ theme }) => theme.adaptive.main.lg} {
    max-width: 22px;
    max-height: 18px;
    margin-right: 32px;
  }
  ${({ theme }) => theme.adaptive.main.sslg} {
    max-width: 20px;
    max-height: 16px;
    margin-right: 26px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    max-width: 25px;
    max-height: 20px;
    margin-right: 48px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    max-width: 20px;
    max-height: 16px;
    margin-right: 26px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin: 0 34px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    margin: 0 32px;
  }
  svg {
    display: block;
    fill: #e94e4e;
    transition: 0.4s;
    max-width: 25px;
    max-height: 20px;
    ${({ theme }) => theme.adaptive.main.lg} {
      max-width: 22px;
      max-height: 18px;
    }
    ${({ theme }) => theme.adaptive.main.sslg} {
      max-width: 20px;
      max-height: 16px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      max-width: 25px;
      max-height: 20px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      max-width: 20px;
      max-height: 16px;
    }
  }
  &:hover {
    svg {
      fill: #6ca8fa;
    }
  }
`
