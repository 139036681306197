import React from 'react'
import styled from 'styled-components'
import { DECK_LINK, CONTACT_LINK } from 'configs/app'
import { ReactComponent as DownloadIcon } from 'img/download.svg'
import iconTexture from 'img/icon-texture.png'

function Navigation() {
  return (
    <Root>
      <Link href={DECK_LINK} target="_blank" color={'#E94E4E'}>
        Business Deck{' '}
        <Icon>
          <DownloadIcon />
        </Icon>
      </Link>
      <Link href={CONTACT_LINK} target="_blank">
        Contact Us
      </Link>
    </Root>
  )
}

export default Navigation

const Root = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.main.sm} {
    position: absolute;
    left: 0;
    bottom: 44px;
    width: 100%;
    background: #f3f3f3;
    padding: 26px 0;
    justify-content: center;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    padding: 20px 0;
  }
`

const Link = styled.a<{ color?: string }>`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 54px;
  line-height: 80%;
  letter-spacing: -0.02em;
  color: ${({ color }) => (color ? color : '#b4b4b4')};
  margin-left: 71px;
  transition: color 0.3s ease;
  &:hover {
    color: #6ca8fa;
    svg {
      stroke: #6ca8fa;
    }
  }
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-left: 59px;
    font-size: 45px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-left: 52px;
    font-size: 41px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    margin-left: 46px;
    font-size: 36px;
  }
  ${({ theme }) => theme.adaptive.main.sslg} {
    margin-left: 36px;
    font-size: 29px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-left: 52px;
    font-size: 54px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-left: 34px;
    font-size: 38px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-left: 59px;
    font-size: 45px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    margin-left: 48px;
    font-size: 40px;
  }
  &:first-child {
    margin-left: 0;
  }
`

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
  width: 28px;
  height: 28px;
  border-radius: 2px;
  background: url(${iconTexture}) no-repeat center #fff;
  svg {
    stroke: #ee6060;
    transition: stroke 0.3s ease;
  }
`
