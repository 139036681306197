export const breakpoints = {
  xl: 1600,
  lg: 1440,
  slg: 1280,
  sslg: 1024,
  md: 860,
  smd: 740,
  sm: 560,
  xsm: 375,
  height: {
    xl: 900,
    lg: 810,
    slg: 720,
    sslg: 576,
    md: 860,
  },
}

const theme = {
  adaptive: {
    main: {
      // xl: `@media screen and (max-width: ${breakpoints.xl}px)`,
      // lg: `@media screen and (max-width: ${breakpoints.lg}px)`,
      // slg: `@media screen and (max-width: ${breakpoints.slg}px)`,
      // sslg: `@media screen and (max-width: ${breakpoints.sslg}px),`,
      xl: `@media screen and (max-width: ${breakpoints.xl}px), screen and (max-height: ${breakpoints.height.xl}px)`,
      lg: `@media screen and (max-width: ${breakpoints.lg}px), screen and (max-height: ${breakpoints.height.lg}px)`,
      slg: `@media screen and (max-width: ${breakpoints.slg}px), screen and (max-height: ${breakpoints.height.slg}px)`,
      sslg: `@media screen and (max-width: ${breakpoints.sslg}px), screen and (max-height: ${breakpoints.height.sslg}px)`,
      // md: `@media screen and (max-width: ${breakpoints.md}px) and (orientation: portrait)`,
      md: `@media screen and (max-width: 1px)`,
      smd: `@media screen and (max-width: ${breakpoints.smd}px)`,
      sm: `@media screen and (max-width: ${breakpoints.sm}px)`,
      xsm: `@media screen and (max-width: ${breakpoints.xsm}px)`,
    },
  },
}

export default theme
